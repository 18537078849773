import axios from "~/plugins/axios";
const qs = require("qs");

export default {
  getUser() {
    return axios
      .get("/ebsn/api/api-tosano/account/user")
      .then(data => data.data.data.value);
  },
  registrationUser(user) {
    let url = "/ebsn/api/tosano-registration/register_user";
    return axios
      .post(url, user)
      .then(data => {
        if (data.data.response.status === 0) {
          return data.data;
        } else {
          Promise.reject(data);
        }
      })
      .catch(err => err);
  },
  resetPassword(email, token, password) {
    let url = "/ebsn/api/tosano-registration/password_reset";
    return axios
      .post(url, qs.stringify({ email, token, password }))
      .then(data => {
        if (data.data.response.status == 0) {
          return data.data;
        } else {
          Promise.reject(data);
        }
      });
  },
  updateUserDetail(user) {
    let url = "/ebsn/api/tosano-registration/update_user_detail";
    return axios
      .post(url, user)
      .then(response => response.data)
      .catch(err => err);
  },
  // getResetPasswordToken(email, fiscalCode) {
  //   let url = "/ebsn/api/tosano-registration/password_reset_token_request";

  //   let params = {};

  //   if (email) {
  //     params["e_mail"] = email;
  //   }
  //   if (fiscalCode) {
  //     params["fiscal_code"] = fiscalCode;
  //   }
  //   return axios
  //     .get(url, {
  //       params: params
  //     })
  //     .then(data => {
  //       if (data.data.response.status == 0) {
  //         return data.data;
  //       } else {
  //         Promise.reject(data);
  //       }
  //     });
  // },
  confirmRegistration(email, controlCode, newEmail) {
    let url = "/ebsn/api/tosano-registration/registration_confirm";
    return axios
      .get(url, {
        params: {
          e_mail: email,
          reg_control_code: controlCode,
          new_email: newEmail
        }
      })
      .then(response => {
        return response.data.response;
      });
  },
  updateUser(user) {
    // let updatedUser = { ...user };
    // updatedUser.BirthDate = new Date(updatedUser.BirthDate).toISOString();
    return axios
      .post("/ebsn/api/api-tosano/account/update-user", user)
      .then(response => {
        if (response.data.response.status == 0) {
          return response.data.data.value;
        } else {
          return Promise.reject(response.data.response);
        }
      });
  },
  updatePassword(oldPassword, newPassword) {
    let url = "/ebsn/api/tosano-registration/update_password";
    return axios
      .get(url, {
        params: { old_password: oldPassword, new_password: newPassword }
      })
      .then(data => {
        return data.data;
      });
  },

  getPosteProvinceList() {
    return axios
      .get("/ebsn/api/api/poste/province-list")
      .then(data => data.data.data.values)
      .catch(error => error);
  },
  getPosteCityList(filter, idProvince) {
    return axios
      .get("/ebsn/api/api-tosano/poste/city-list", {
        params: { id_province: idProvince, filter }
      })
      .then(data => data.data.data.values)
      .catch(error => error);
  },
  getIstatCode(istatCodeProvince, idCity) {
    return axios
      .get("/ebsn/api/api-tosano/poste/get-istat-code", {
        params: { istat_code_province: istatCodeProvince, id_localita: idCity }
      })
      .then(data => data.data.data.value)
      .catch(error => error);
  },
  getPosteAddressList(filter, idCity) {
    return axios
      .get("/ebsn/api/api-tosano/poste/address-suggestions", {
        params: { id_localita: idCity, filter }
      })
      .then(data => data.data.data.values)
      .catch(error => error);
  },
  checkUser(user) {
    return axios
      .post("/ebsn/api/api-tosano/account/check-user", user)
      .then(data => {
        if (data.data.response.status == 0) {
          return data.data.data.value;
        } else {
          return Promise.reject(data.data.response);
        }
      });
  },
  getFiscalCode(
    name,
    surname,
    birthDate,
    gender,
    istatCodeProvince,
    idLocalita
  ) {
    try {
      return axios
        .get("/ebsn/api/api-tosano/account/generate-fiscal-code", {
          params: {
            name,
            surname,
            gender,
            birthDate,
            istat_code_province: istatCodeProvince,
            id_localita: idLocalita
          }
        })
        .then(data => {
          // console.log(data.data);
          return data.data.data.value.fiscalCode;
        });
    } catch (error) {
      return null;
    }
  },
  getIDCode(
    name,
    surname,
    birthDate,
    birthCity,
    birthCountry,
    birthZipCode,
    birthForeignProvince
  ) {
    // name=Diego&surname=Fenu&birthDate=1996-03-15&gender=M&istat_code_province=058&id_localita=5938
    try {
      return axios
        .get("/ebsn/api/api-tosano/account/get-id-code", {
          params: {
            name,
            surname,
            birthDate,
            birthCity,
            birthCountry,
            birthZipCode,
            birthForeignProvince
          }
        })
        .then(data => {
          // console.log(data.data);
          return data.data.data.value.fiscalCode;
        });
    } catch (error) {
      return null;
    }
  },

  getCountries(continentId, homeDeliveryOnly) {
    return axios
      .get("/ebsn/api/geo/country-list", {
        params: {
          continent_id: continentId,
          home_delivery_only: homeDeliveryOnly
        }
      })
      .then(data => {
        return data.data.data.results;
      });
  }
};
