<template>
  <v-container class="flex-column mt-1 register">
    <CategoryTitle :category="category" />

    <v-form class="mt-1" ref="form" v-model="isValid">
      <span
        class="d-block text-uppercase font-weight-bold secondary--text mb-6"
      >
        {{ $t("registration.subtitle") }}
      </span>
      <v-row class="mb-3">
        <v-col cols="12" sm="6">
          <v-text-field
            color="secondary"
            v-model="registerData.person.firstName"
            :label="$t('registration.field.firstName')"
            :rules="[requiredRules()]"
            required
            outlined
            dense
            :disabled="isSocialLogin"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            color="secondary"
            v-model="registerData.person.lastName"
            :rules="[requiredRules()]"
            :label="$t('registration.field.lastName')"
            required
            outlined
            dense
            :disabled="isSocialLogin"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            color="secondary"
            v-model="registerData.email"
            :label="$t('registration.field.email')"
            :rules="emailRules"
            required
            outlined
            dense
            @blur="removeSpaces(registerData.email)"
            :disabled="isSocialLogin"
            ><v-tooltip slot="append" top max-width="300" open-on-click>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">$info</v-icon>
              </template>
              <span>{{ $t("registration.emailTooltip") }}</span>
            </v-tooltip></v-text-field
          >
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="registerData.phone"
            :label="$t('registration.field.phone')"
            outlined
            dense
            :rules="isPhoneNumberRules"
            @blur="removeSpaces(registerData.phone)"
          >
            <v-tooltip slot="append" top max-width="300" open-on-click>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">$info</v-icon>
              </template>
              <span>{{ $t("registration.phoneTooltip") }}</span>
            </v-tooltip></v-text-field
          >
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            color="secondary"
            v-model="registerData.password"
            :label="$t('registration.field.password')"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordRules"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            color="secondary"
            v-model="registerData.passwordConfirm"
            :label="$t('registration.field.passwordConfirm')"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordConfirmRules"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>

        <!-- HAS FISCAL CODE?-->
        <v-col cols="12">
          <v-switch
            inset
            :label="$t('profile.hasFiscalCode')"
            class="italian-citizen mb-2"
            color="secondary"
            v-model="noFiscalCode"
          />
        </v-col>

        <v-col cols="12" sm="6" v-if="!noFiscalCode">
          <v-text-field
            v-model="registerData.person.fiscalCode"
            :label="$t('registration.field.fiscalCode')"
            :rules="isCfRules"
            outlined
            dense
            required
            @blur="removeSpaces(registerData.person.fiscalCode)"
          >
            <v-tooltip slot="append" top max-width="300" open-on-click>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">$info</v-icon>
              </template>
              <span>{{ $t("registration.fiscalCodeTooltip") }}</span>
            </v-tooltip>
          </v-text-field>
        </v-col>

        <v-col cols="12" sm="6" v-if="noFiscalCode">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="birthDate"
                :label="$t('registration.field.birthDate')"
                :rules="ageRules"
                required
                readonly
                outlined
                dense
              >
                <v-icon v-bind="attrs" v-on="on" slot="append"
                  >$editcalendar</v-icon
                >
              </v-text-field>
            </template>
            <v-date-picker
              locale="it-IT"
              ref="picker"
              :active-picker.sync="activePicker"
              v-model="registerData.person.birthDate"
              min="1910-01-01"
              :max="$dayjs().format('YYYY-MM-DD')"
              first-day-of-week="1"
              type="date"
              no-title
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          v-if="noFiscalCode"
          cols="12"
          sm="6"
          class="d-flex flex-column flex-sm-row align-start"
        >
          <!-- GENDER -->
          <v-radio-group
            dense
            v-model="registerData.person.gender"
            mandatory
            :column="!$vuetify.breakpoint.xs"
            :label="$t('registration.field.gender')"
            class="gender pt-2"
            row
            :rules="[requiredRules()]"
          >
            <v-radio value="M" color="secondary"
              ><template v-slot:label>
                <span>M</span>
              </template>
            </v-radio>
            <v-radio value="F" color="secondary"
              ><template v-slot:label>
                <span>F</span>
              </template>
            </v-radio></v-radio-group
          >
        </v-col>

        <!-- BORNCOUNTRY -->
        <v-col cols="12" sm="6" v-if="noFiscalCode">
          <v-combobox
            :label="$t('registration.field.birthCountry')"
            v-model="bornCountry"
            :items="countries"
            required
            height="40"
            :rules="requiredCountry"
            outlined
            dense
            return-object
            append-icon=""
            item-text="name"
            item-value="name"
            @change="selectBornCountry"
          ></v-combobox>
        </v-col>

        <!-- BIRTHPLACE -->
        <v-col cols="12" sm="6" v-if="noFiscalCode">
          <v-text-field
            :placeholder="$t('registration.field.birthPlace')"
            v-model="registerData.person.birthPlace"
            required
            :rules="[requiredRules()]"
            outlined
            dense
          />
        </v-col>
        <v-col cols="12">
          <span
            class="d-block text-uppercase font-weight-bold secondary--text mb-4"
          >
            {{ $t("registration.subtitle2") }}
          </span>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            v-model="registerData.billingAddress.address1"
            name="address"
            :label="$t('registration.field.address1')"
            outlined
            dense
            :rules="[requiredRules()]"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="registerData.billingAddress.addressNumber"
            name="address number"
            :label="$t('registration.field.addressNumber')"
            outlined
            dense
            :rules="[requiredRules()]"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="registerData.billingAddress.postalcode"
            :label="$t('registration.field.postalCode')"
            name="postal code"
            outlined
            dense
            :rules="[requiredRules()]"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="registerData.billingAddress.city"
            :label="$t('registration.field.city')"
            name="city"
            outlined
            dense
            :rules="[requiredRules()]"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="registerData.billingAddress.province"
            name="province"
            :label="$t('registration.field.province')"
            outlined
            dense
            :rules="[requiredRules()]"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- privacy -->
      <div class="privacy-section">
        <RegistrationModuleDisclaimer
          v-if="disclaimerList"
          :disclaimerList="disclaimerList"
          :options="disclaimerOptions"
          @disclaimerChanged="disclaimerChanged"
        />
      </div>
    </v-form>
    <response-message :response="response" />
    <div>
      <v-btn
        class="primary registration-button"
        @click="handleRegister"
        :disabled="disabledRegistration"
        large
        depressed
        min-width="300"
        :loading="loading"
      >
        {{ $t("registration.submitRegistration") }}
      </v-btn>
    </div>

    <RecaptchaDisclaimer @click="clicked" />
  </v-container>
</template>

<style scoped lang="scss">
.register {
  div[class*="col-"] {
    padding: 0 12px !important;
  }
  .gender {
    margin-top: 0;
  }
  .italian-citizen {
    margin-top: 0;
  }
  .info-tooltip-icon {
    height: 32px;
  }
  .recaptcha-disclaimer {
    margin-top: 40px;
  }
}
</style>

<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import RegistrationModuleDisclaimer from "@/commons/components/disclaimer/RegistrationModuleDisclaimer.vue";
import ResponseMessage from "@/components/ResponseMessage.vue";
import RecaptchaDisclaimer from "@/commons/components/RecaptchaDisclaimer.vue";

import clickHandler from "~/mixins/clickHandler";
import categoryMixins from "~/mixins/category";

import UserService from "~/service/userService";
import SocialService from "~/service/socialService";
import CodiceFiscale from "codice-fiscale-js";

import TosanoCustomService from "@/service/TosanoCustomService";

import { mapActions } from "vuex";

import {
  requiredValue,
  isEmail,
  minLength,
  isGenericPhone,
  minCapitalCharacter,
  minSymbolCharacter
} from "~/validator/validationRules";

import { isAdultAge, isValidCf, isValidCountryItem } from "@/tosanoValidators";

export default {
  name: "Register",
  props: ["nextUrl"],
  components: {
    RecaptchaDisclaimer,
    CategoryTitle,
    RegistrationModuleDisclaimer,
    ResponseMessage
  },
  mixins: [categoryMixins, clickHandler],
  data() {
    return {
      menu: false,
      showPassword: false,
      isSocialLogin: false,
      registrationModule: {},
      noFiscalCode: false,
      activePicker: null,
      registerData: {
        person: {
          firstName: null,
          lastName: null,
          fiscalCode: null,
          gender: "M",
          birthPlace: null
        },
        passwordConfirm: null,
        email: null,
        password: null,
        billingAddress: {
          address1: null,
          addressNumber: null,
          postalcode: null,
          city: null,
          province: null,
          country: null
        },
        phone: null
      },
      bornCountry: { itemId: null, name: null },
      disclaimerList: null,
      registrationModules: null,
      isValid: false,
      lazy: true,
      countries: [],
      disclaimerOptions: {
        color: "secondary"
      },
      requiredRules: requiredValue,
      requiredCountry: [
        requiredValue("Selezionare una nazione"),
        isValidCountryItem()
      ],
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      ageRules: [
        requiredValue("Inserire la data di nascita"),
        isAdultAge("L'iscrizione al sito è riservata ai maggiorenni")
      ],
      passwordRules: [
        requiredValue("Digitare la password"),
        minLength(8, "La password deve essere lunga almeno 8 caratteri"),
        minCapitalCharacter(),
        minSymbolCharacter()
      ],
      passwordConfirmRules: [
        v => !!v || "Confermare la password",
        v => v === this.registerData.password || "Le password non coincidono"
      ],
      isPhoneNumberRules: [
        requiredValue("Digitare il numero di telefono"),
        isGenericPhone()
      ],
      isCfRules: [requiredValue("Digitare il codice fiscale"), isValidCf()],

      loading: false,
      response: {
        errors: []
      }
    };
  },
  computed: {
    disabledRegistration() {
      return !this.isValid;
    },
    birthDate: {
      get: function() {
        if (
          this.registerData.person.birthDate &&
          this.$dayjs(this.registerData.person.birthDate).isValid()
        ) {
          return this.$dayjs(this.registerData.person.birthDate).format(
            "DD/MM/YYYY"
          );
        } else {
          return null;
        }
      },
      set: function(value) {
        if (value) {
          this.registerData.person.birthDate = this.$dayjs(
            value,
            "DD/MM/YYYY"
          ).format("YYYY-MM-DD");
        } else {
          this.registerData.person.birthDate = null;
        }
      }
    }
  },
  methods: {
    ...mapActions({
      getCart: "cart/getCart"
    }),
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    async loadCountriesList() {
      let allCountries = await TosanoCustomService.getCountries(-1);
      this.countries = allCountries;
    },
    onFiscalCodeToggle(value) {
      this.registerData.person.fiscalCode = null;
      this.registerData.person.gender = "M";
      this.registerData.person.birthPlace = null;
      this.bornCountry = { itemId: null, name: null };
      if (value) {
        this.registerData.person.birthDate = null;
      }
    },
    removeSpaces(val) {
      if (
        this.registerData.person.fiscalCode &&
        val == this.registerData.person.fiscalCode
      ) {
        this.registerData.person.fiscalCode = val.replace(
          new RegExp(/\s/g),
          ""
        );
      } else if (this.registerData.email && val == this.registerData.email) {
        this.registerData.email = val.replace(new RegExp(/\s/g), "");
      } else if (this.registerData.phone && val == this.registerData.phone) {
        this.registerData.phone = val.replace(new RegExp(/\s/g), "");
      }
    },
    selectBornCountry(item) {
      this.bornCountry = item;
    },
    formatDate(date) {
      return this.$dayjs(date).format("yyyy-MM-dd'");
    },
    disclaimerChanged(registrationModules) {
      this.registrationModules = registrationModules;
    },
    // updateFiscalCode() {
    //   this.registerData.person.fiscalCode = new CodiceFiscale({
    //     name: this.registerData.person.firstName,
    //     surname: this.registerData.person.lastName,
    //     gender: this.registerData.person.gender,
    //     day: this.registerData.person.birthDate.day(),
    //     month: this.registerData.person.birthDate.month(),
    //     year: this.registerData.person.birthDate.year(),
    //     birthplace: this.registerData.billingAddress.country,
    //     birthplaceProvincia: this.registerData.billingAddress.province // Optional
    //   });
    // },
    getBirthdayFromFiscalCode() {
      var cf = new CodiceFiscale(this.registerData.person.fiscalCode);
      if (cf.birthday) {
        this.registerData.person.birthDate = this.$dayjs(cf.birthday).format(
          "YYYY-MM-DD"
        );
      }
    },
    // decodeFiscalCode() {
    //   try {
    //     var cf = new CodiceFiscale(this.registerData.person.fiscalCode);
    //     if (cf.birthday) {
    //       this.registerData.person.birthDate = this.$dayjs(cf.birthday);
    //       this.$dayjs().diff(this.registerData.person.birthDate, "year", true) <
    //       18
    //         ? this.response.errors.push({
    //             error: "L'iscrizione al sito è riservata ai maggiorenni"
    //           })
    //         : this.response.errors.pop();
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    async handleRegister() {
      try {
        this.loading = true;
        if (this.$refs.form.validate()) {
          this.registerData.login = this.registerData.email;
          this.registerData.person.personInfos = [
            // has no fiscal code
            {
              personInfoTypeId: 11,
              value: this.noFiscalCode
            },
            // bornCountry mapping
            {
              personInfoTypeId: 14,
              value: !this.noFiscalCode ? 1 : this.bornCountry.itemId
            }
          ];

          if (
            !this.noFiscalCode &&
            this.registerData.person.fiscalCode &&
            !this.registerData.person.birthday
          ) {
            this.registerData.person.birthday = this.getBirthdayFromFiscalCode();
          }

          let registrationModulesParam = this.registrationModules.map(
            disclaimer => {
              return {
                value: disclaimer.value,
                registrationDisclaimer: {
                  registrationDisclaimerId: disclaimer.registrationDisclaimerId
                }
              };
            }
          );
          this.registerData.registrationModule = {
            registrationModuleAction: {
              name: "Inserimento",
              registrationModuleActionId: 2
            },
            registrationModuleType: {
              description: "",
              name: "REG_WEB",
              registrationModuleTypeId: 2
            },
            registrationModuleDisclaimers: registrationModulesParam
          };

          var _this = this;

          let response = await TosanoCustomService.registrationUser(
            this.registerData
          );
          console.log(response);
          this.loading = false;
          if (response.response && response.response.status === 0) {
            let result = await UserService.updateUserDisclaimers(
              _this.registerData.registrationModule
            );
            if (
              result &&
              result.registrationModules &&
              result.registrationModules.length > 0
            ) {
              console.log(result);
            }
            this.getCart();
            if (this.isSocialLogin) {
              this.$router.push({
                path: "/registration-social-completed",
                name: "RegistrationSocialCompleted",
                params: {
                  email: this.registerData.login,
                  is_social_reg: true
                }
              });
            } else {
              this.$router.push({
                path: "/registration-completed",
                name: "RegistrationCompleted",
                params: {
                  email: this.registerData.login
                }
              });
            }
          } else {
            if (
              response.errorsMessage[0]?.code ===
              "error.registration.fiscalcode_already_registered"
            ) {
              this.redirectToResetPassword(response.errorsMessage[0].message);
            } else {
              this.response = response;
            }
            this.response = response;
          }
        }
      } catch (response) {
        if (typeof response === "undefined") {
          this.response = {
            errors: [{ error: this.$t("errors.errorCompletingRegistration") }]
          };
        } else {
          this.response = response;
        }
      } finally {
        this.loading = false;
      }
    },
    async loadDisclaimer() {
      let storeId = 5;
      let registrationModuleTypeId = 2;
      let res = await UserService.getRegistrationDisclaimerList(
        registrationModuleTypeId,
        storeId
      );
      this.disclaimerList = res.registrationDisclaimers;
      console.log("getDisclaimer", res);
    },
    redirectToResetPassword(errorMessage) {
      this.$router.push({
        name: "ResetPasswordRequest",
        params: {
          message: errorMessage,
          isResetFromRegistration: true,
          fiscalCode: this.registerData.person.fiscalCode
        }
      });
    }
  },
  mounted() {
    let socialLogin = this.$route.query.social_login;
    if (socialLogin) {
      //get social_user_detail
      SocialService.getSocialUserDetails().then(
        resp => {
          console.log("getSocialUserDetails:", resp);
          this.registerData = resp.data.user;
          // set email field to readonly
          this.isSocialLogin = true;
        },
        function(err) {
          console.log("getSocialUserDetails ERROR:", err);
        }
      );
    }
    this.loadCountriesList();
    this.loadDisclaimer();
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    }
  }
};
</script>
